import React from "react"
import EmbedSlidesPresentation from "../../components/EmbedSlidesPresentation/EmbedSlidesPresentation"
import SlidesPresentation from "../../templates/slides-presentation"

const InhouseRecruitmentNl = (): React.ReactElement => {
  return (
    <SlidesPresentation>
      <EmbedSlidesPresentation iframeSrc="https://slides.com/bramtierie/flipbase-geintegreerde-asynchrone-video-interactie-inhouse-recruitment-nl/embed" />
    </SlidesPresentation>
  )
}

export default InhouseRecruitmentNl
