import React from "react"
import { GatsbyLocation } from "local-types"
import SEO from "../components/Seo/Seo"
import styled from "styled-components"
import { colors, device } from "../styles/constants"
import { useSiteMetadata } from "../hooks"

const standartMetadata = {
  Title: "Flipbase",
  Keywords: ["Flipbase"],
  Description:
    "We are a software company with the mentality of a pioneer, always trying to be ahead of the market and constantly searching for and developing new technology. Read more about Flipbase.",
  Image: "/seo-image-general.png",
}

interface EmbedSlidesPresentation {
  iframeSrc: string
}

const EmbedSlidesPresentation: React.FunctionComponent<
  EmbedSlidesPresentation
> = ({ iframeSrc }): React.ReactElement => {
  return (
    <Container>
      <iframe
        src={iframeSrc}
        className="responsive-iframe"
        scrolling="no"
        frameBorder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 55.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`

export default EmbedSlidesPresentation
