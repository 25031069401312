import * as React from "react"
import SEO from "../components/Seo"

type Props = {
  children: React.ReactElement | React.ReactElement[]
}

const SlidesPresentationTemplate = ({ children }: Props) => {
  return (
    <div>
      <SEO />
      {children}
    </div>
  )
}

export default SlidesPresentationTemplate
